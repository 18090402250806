.input-t {
  width: 100%;
  outline: 0;
  background-color: white !important;
  border: 1px solid #999999;
  outline: none;
  border-radius: 20px;
  font-family:  "Public Sans", sans-serif;
}

input:focus{
  border: 2px solid #5928e5;
}

@media screen and (max-width: 768px) {
  .input-t {
    width: 100%;
  }
}
