.edd-lead {
  font-size: 48px;
  font-weight: 500;
  font-family: "Bree Serif", sans-serif;
}

.edd-p {
  font-size: 24px;
  line-height: 40px;
  font-weight: 400;
  color: rgb(51, 51, 51);
  font-family: "Public Sans", sans-serif;
}

@media screen and (max-width: 960px) {
  .edd-lead {
    font-size: 36px;
    font-weight: 500;
    font-family: "Bree Serif", sans-serif;
    letter-spacing: 0.3px;
  }

  .edd-p {
    font-size: 20px;
    line-height: 30px;
    letter-spacing: 0.1px;
    font-weight: 400;
    color: rgb(51, 51, 51);
    font-family: "Public Sans", sans-serif;
  }
}
