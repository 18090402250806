footer {
  background-color: #efefef;
  color: white;
  cursor: pointer;
  font-family: "Source Sans Pro", sans-serif;
}

.footer-margin-up {
  margin-top: '';
}

.footer-tx > div {
  line-height: 2.2rem;
}

@media screen and (max-width: 960px) {
  .footer-margin-up {
    margin-top: 3rem;
  }

  .join-us{
    font-size: 14px;
  }

  .footer-tx > div {
    line-height: 2.2rem;
    font-size: 14px;
    font-weight: 500;
  }
}
