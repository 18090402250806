.button {
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  line-height: 26px;
  border: transparent;
  outline: 1px solid transparent !important;
}

.curve-btn {
  border-radius: 50px;
}
.disabled-button {
  width: 100%;
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
  background: #d3d3d3;
  border-radius: 3px;
  cursor: not-allowed;
  pointer-events: all !important;
  height: 41px;
}

.dark {
  background: #046b99;
  color: #fff;
  border-radius: 16px;
  height: 60px;
  font-size: 21px;
  width: 40%;
  font-family: "Bree Serif", sans-serif;
  padding: 10px 70px;
  white-space: nowrap;
}

.str-dark {
  background: #046b99;
  color: #fff;
  border-radius: 4px;
  height: 42px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  font-family: "Source Sans Pro", sans-serif;
  padding: 10px 17px;
  white-space: nowrap;
}


.nude-edd {
  background: #f5f5f5;
  color: #555555;
  border: 1px solid #555555;
  width: 40%;
  font-size: 21px;
  font-family: "Bree Serif", sans-serif;
  border-radius: 16px;
  height: 60px;
  padding: 10px 70px;
  white-space: nowrap;
}

.nude-payment {
  background: #f5f5f5;
  color: #5a555a;
  border: 1px solid #555555;
  font-size: 16px;
  font-family: "Source Sans Pro", sans-serif;
  border-radius: 4px;
  height: 40px;
  padding: 0px 50px;
  white-space: nowrap;
}

.claim-btn {
  background: #f5f5f5;
  color: #5f5a83;
  border: 1px solid #555555;
  border-radius: 6px;
  height: 40px;
  font-family: "Source Sans Pro", sans-serif;
  white-space: nowrap;
  font-size: 16px;
  padding: 5px 20px;
}

@media screen and (max-width: 768px) {
  .dark {
    background: #046b99;
    color: #fff;
    width: 100%;
    white-space: nowrap;
  }

  .nude-edd {
    background: #f5f5f5;
    color: #555555;
    border: 1px solid #555555;
    width: 100%;
    white-space: nowrap;
  }
}
