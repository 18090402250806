.edd-top-border-m{
    background-color: #046b99;
    border: 1px solid #046b99;
    padding: 10px 15px;
    border-bottom: 5px solid #034867;
    color: white;
}


.ed-card{
    border: 1px solid #dddddd;
    background-color: white;
    padding: 15px 13px;
    color:#403333
}