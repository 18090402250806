.head-primary-bg {
  border: 1px solid #046b99;
  background-color: #046b99;
  padding: 2px 10px;
  color: white;
  font-size: 14px;
  cursor: pointer;
  height: 49px;
  font-weight: 400;
}

.hero-input {
  width: 400px;
  outline: 0;
  background-color: white !important;
  border: 1px solid #e8e8e8;
  outline: none;
  height: 50px;
  outline: 0;
  border-radius: 8px;
  padding: 12px 15px;
  color: #000000;
  font-weight: 300;
}

.lead-texty {
  line-height: 3.6rem;
  color: #fff;
  font-size: 43px;
  font-weight: bolder;
}

.link-menu-active {
  color: #fff;
  text-decoration: underline;
}

.link-menu-active-blue {
  color: #5928e5;
  text-decoration: underline;
}

.link-menu-inactive {
  color: #000000;
}

.link-ed {
  font-family: "Source Sans Pro", sans-serif;
  line-height: 23.2px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
}

.link-ed:hover {
  color: rgb(181, 94, 0);
}

.hero-edd {
  background-color: red;
  padding: 20px;
  height: 60px;
  border: 1px solid white;
  border-bottom: 3px solid orange;
}

.hero-sec {
  color: white;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 130px;
  background-color: #5928e5;
  height: 100%;
}

@media screen and (max-width: 340px) {
  .hero-input {
    width: 200px;
  }
}

@media screen and (max-width: 960px) {
  .hero-sec {
    height: 100%;
    padding-top: 100px;
  }

  .head-primary-full {
    border: 1px solid #046b99;
    background-color: #046b99;
    padding: 2px 5px;
    color: white;
    font-size: 14px;
    cursor: pointer;
    height: 83px;
    font-weight: 400;
  }

  .head-primary-bg {
    border: 1px solid #046b99;
    background-color: #046b99;
    padding: 2px 5px;
    color: white;
    font-size: 14px;
    cursor: pointer;
    height: 49px;
    font-weight: 400;
  }

  .head-primary {
    border: 1px solid #fff;
    background-color: #fff;
    padding: 13px 10px;
    font-size: 14px;
    cursor: pointer;
    font-weight: 400;
  }

  .head-primary-dark {
    border: 1px solid #5928e5;
    background-color: #5928e5;
    padding: 13px 10px;
    color: white;
    font-size: 14px;
    cursor: pointer;
    font-weight: 400;
  }

  .lead-texty {
    line-height: 2.5rem;
    color: #fff;
    font-size: 23px;
    font-weight: bolder;
  }

  .hero-input {
    width: 300px;
  }
}

@media screen and (min-width: 960px) {
  .hero-sec {
    height: 100%;
  }
}

@media screen and (min-width: 1410px) {
  .hero-sec {
    height: 100%;
  }
}
