@import url("https://fonts.cdnfonts.com/css/public-sans");
@import url('https://fonts.cdnfonts.com/css/bree-serif-2');
@import url('https://fonts.cdnfonts.com/css/source-sans-pro');

* {
  padding: 0;
  margin: 0;
}

.table-sizee {
  font-size: 15px !important;
  padding: 20px 30px;
}

.suns-font{
  font-family: "Source Sans Pro", sans-serif;
}

.public-font{
  font-family: "Public Sans", sans-serif;
}

.pulseWrapper {
  min-height: 50vh;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.form-label {
  font-weight: 600;
  color: #003366;
}
.scrollbar {
  border: 1px solid black;
  height: 100%;
}
.pulse {
  height: 45px;
  width: 45px;
  padding: 10px;
  border-radius: 50%;
  animation: pulse 1.5s infinite;
}

.input-className {
  width: 100%;
}

.success-rive {
  background: #205732;
  color: white;
  border-radius: 10px;
  padding: 10px 20px;
  cursor: pointer;
  white-space: nowrap;

  width: 100px !important;
  margin: 0;
}

.styles_faq-row-wrapper__3vA1D
  .styles_row-body__1NvUo
  .styles_faq-row__2YF3c
  .styles_row-title__1YiiY {
  padding: 10px 0 !important;
  display: flex !important;
  justify-content: space-between !important;
  color: var(--row-title-color, black) !important;
  cursor: pointer !important;
  align-items: center !important;
  font-size: 15px !important;
  font-weight: bold;
}

.styles_faq-row-wrapper__3vA1D
  .styles_row-body__1NvUo
  .styles_faq-row__2YF3c
  .styles_row-content__QOGZd
  .styles_row-content-text__2sgAB {
  color: var(--row-content-color, black) !important;
  font-size: 13.5px !important;
 
}

.info-rive {
  background: #003366;
  color: white;
  border-radius: 10px;
  padding: 10px 20px;
  width: fit-content !important;
  white-space: nowrap;
  margin: 0;
  cursor: pointer;
}

.danger-rive {
  background: red;
  color: #fff;
  border-radius: 10px;
  cursor: pointer;
  margin: 0;
  padding: 10px 20px;
  white-space: nowrap;
  width: 100px !important;
}

.container-fluid-x-head {
  padding: 0px 40px;
}

.container-fluid-x {
  padding: 0px 20px;
}

.tight-pad {
  padding: 50px 20px;
}

body {
  height: 100% !important;
  background-color: #e5f1fd;
}

.react-tel-input .country-list .country-name {
  margin-right: 6px;
  color: black !important;
}
.react-tel-input .form-control {
  position: relative;
  font-size: 14px;
  letter-spacing: 0.01rem;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding-left: 48px;
  margin-left: 0;
  background: #ffffff !important;
  border: 1px solid #cacaca;
  border-radius: 5px;
  line-height: 25px;
  height: 50px !important;
  width: 100% !important;
  outline: none;
}

.mobile-margin-up {
}

.img-int {
  width: 100%;
}

.flex-sys {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.flex-sys-r {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.show-mobile-interface {
  display: none !important;
}
.show-web-interface {
  display: block !important;
}

.flex-sys-mob {
  display: flex;
  align-items: center;
}

.validate-error {
  color: red;
  font-size: 12px;
}

@media screen and (min-width: 1206px) {
  .container-fluid-x {
    padding: 0px 40px;
  }
}

@media screen and (max-width: 960px) {
  .container-fluid-x {
    padding: 0px 0px;
  }
  .container-fluid-x-head {
    padding: 0px 10px;
  }
  
}

@media screen and (min-width: 1650px) {
  .container-fluid-x {
    padding: 0px 210px;
  }
}

@media screen and (max-width: 960px) {
  .show-mobile-interface {
    display: block !important;
  }
  .show-web-interface {
    display: none !important;
  }

  .flex-sys-r {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .flex-sys-mob {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .flex-sys {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .mobile-margin-up {
    margin-top: 2rem;
  }
  .img-int {
    width: 100%;
  }
}

.dot-circ {
  border: 1px solid #fff;
  background-color: #fff;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  text-align: center;
}

.text-icon-red {
  color: #e40219;
}

.show-web-interface {
  display: block !important;
}
.show-mobile-interface {
  display: none !important;
}

.pad-xo{
  padding: 0px 40px;
}


@media screen and (max-width: 960px) {
  .show-mobile-interface {
    display: block !important;
  }
  .show-web-interface {
    display: none !important;
  }
  .pad-xo{
    padding: 0px 20px;
  }
}