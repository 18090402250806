.edd-top-border {
  background-color: #fdb81e;
  border: 1px solid #fdb81e;
  padding: 10px 15px;
  color: rgb(51, 51, 51);
}

.claim-top-border {
    background-color: #f5f5f5;
    border: 1px solid #dddddd;
    padding: 10px 15px;
    color: rgb(51, 51, 51);
  }
  

.ed-card {
  border: 1px solid #fdb81e;
  background-color: white;
  padding: 15px 13px;
  color: #403333;
}

.ed-card-claim {
    border: 1px solid #dddddd;
    background-color: f5f5f5;
    padding: 15px 13px;
    color: #403333;
  }
  

.not-collapse {
  border: 1px solid #dddddd;
  background-color: #f5f5f5;
  padding: 15px;
}
